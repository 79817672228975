import * as React from "react"
import { Link } from "gatsby"
import Layout from '../components/Layout'
import styled from 'styled-components'
import SEO from '../components/SEO'
// markup
const NotFoundPage = () => {
  return (
    <Layout>
      <SEO title="404" />
      <Styled404>
        <StyledH2>404</StyledH2>
        <StyledLink to="/">Strona główna</StyledLink>
      </Styled404>
    </Layout>
  )
}

const Styled404 = styled.section`
min-height: 91vh;
background: linear-gradient(267.37deg, #ECEBEE 1.05%, rgba(250, 250, 250, 0.06) 122.76%);
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
`

const StyledH2 = styled.h2`
color: ${({ theme }) => theme.color.blue};
font-size: 20rem;
margin: 2rem 2rem 1rem 2rem;
`

const StyledLink = styled(Link)`
font-size: 3rem;
margin: 1rem 2rem 2rem 2rem;
`
export default NotFoundPage
